<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Ajustes" :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idconfiguracion)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>

        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>

    <Dialog-Form :estate="dialogs.new" title="Nuevo valor">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.tipo" label="Tipo" prepend-icon="mdi-calendar-clock" :rules="rules.max100" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.descripcion" label="Descripción" prepend-icon="mdi-message-reply-text-outline" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="text-center pt-0">
            <div>
              <h2 class="titulo text-center" v-text="'Valores globales'" ></h2>
            </div>
            <div :class="auxValue.length == 0 ? 'text-center': 'text-right'">
              <Basic-Btn text="Eliminar" color="error" icon="mdi-delete" @click="deleteGlobal()" v-if="deleteGlobals" :loading="loading" />
              <Basic-Btn :text="deleteGlobals ? 'Cancelar' : 'Eliminar'" :color="deleteGlobals ? 'disabled' : 'error'" :icon="deleteGlobals ? 'mdi-close' : 'mdi-delete'" @click="deleteGlobals = !deleteGlobals" :loading="loading" :disabled="auxValue == 0" />
              <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="addGlobal('new')" :loading="loading"  />
            </div>
          </v-col>
          <v-col v-for="(data, index) in auxValue" :key="index" cols="12" md="12">
            <v-row>
              <v-col cols="1" md="1" v-if="deleteGlobals">
                <v-checkbox class="ma-0" v-model="auxValue[index].delete" color="secondary" hide-details></v-checkbox>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field hide-details v-model="auxValue[index].key" :label="'Nombre ' + (index + 1)" :prepend-icon="'mdi-numeric-' + (index > 8 ? '9-plus' : index + 1) + '-circle-outline'" :disabled="loading" outlined dense></v-text-field>
              </v-col>
              <v-col :cols="deleteGlobals ? 5 : 6" :md="deleteGlobals ? 5 : 6">
                <v-text-field hide-details v-model="auxValue[index].value" :label="'Valor ' + (index + 1)" prepend-icon="mdi-information-outline" :disabled="loading" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar valor">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.tipo" label="Tipo" prepend-icon="mdi-calendar-clock" :rules="rules.max100" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.descripcion" label="Descripción" prepend-icon="mdi-message-reply-text-outline" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="text-center pt-0">
            <div>
              <h2 class="titulo text-center" v-text="'Valores globales'" ></h2>
            </div>
            <div :class="auxValue.length == 0 ? 'text-center': 'text-right'">
              <Basic-Btn text="Eliminar" color="error" icon="mdi-delete" @click="deleteGlobal()" v-if="deleteGlobals" :loading="loading" />
              <Basic-Btn :text="deleteGlobals ? 'Cancelar' : 'Eliminar'" :color="deleteGlobals ? 'disabled' : 'error'" :icon="deleteGlobals ? 'mdi-close' : 'mdi-delete'" @click="deleteGlobals = !deleteGlobals" :loading="loading" :disabled="auxValue == 0" />
              <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="addGlobal('update')" :loading="loading"  />
            </div>
          </v-col>
          <v-col v-for="(data, index) in auxValue" :key="index" cols="12" md="12">
            <v-row>
              <v-col cols="1" md="1" v-if="deleteGlobals">
                <v-checkbox class="ma-0" v-model="auxValue[index].delete" color="secondary" hide-details></v-checkbox>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field hide-details v-model="auxValue[index].key" :label="'Nombre ' + (index + 1)" :prepend-icon="'mdi-numeric-' + (index > 8 ? '9-plus' : index + 1) + '-circle-outline'" :disabled="loading" outlined dense></v-text-field>
              </v-col>
              <v-col :cols="deleteGlobals ? 5 : 6" :md="deleteGlobals ? 5 : 6">
                <v-text-field hide-details v-model="auxValue[index].value" :label="'Valor ' + (index + 1)" prepend-icon="mdi-information-outline" :disabled="loading" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>

    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el valor?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage } from "@/components";

export default {
  name: "amounts",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
  },
  data: () => ({
    loading: false,
    deleteGlobals: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idconfiguracion" },
      { text: "Tipo", align: "left", sortable: true, value: "tipo" },
      { text: "Descripción", align: "left", sortable: true, value: "descripcion" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    selected: [],
    auxValue: [],
    deleted: null,
    forms: {
      new: {
        tipo: "",
        descripcion: "",
        valores: {
        },
      },
      update: {
        idconfiguracion: null,
        tipo: "",
        descripcion: "",
        valores: {
          falta: 0,
          retardo: 0,
        },
      },
    },
    rules: {
      max100: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.setting)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
          this.auxValue = [];
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
        this.auxValue = [];
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
      this.deleteGlobals = false;
    },
    save() {
      let dup = _.uniqBy(this.auxValue, 'key')
      if(dup.length == this.auxValue.length){
      this.infoGlobal('new')
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.setting, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Valor creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
      }else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "No se permiten los nombres duplicados";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.dialogs.update = true;
      for (let key in this.forms.update.valores) {
        this.addGlobal('update', key)
      }
    },
    update() {
      let dup = _.uniqBy(this.auxValue, 'key')
      if(dup.length == this.auxValue.length){
      this.infoGlobal('update')
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.setting, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Valor actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
      }else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "No se permiten los nombres duplicados";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let id = this.deleted;
        this.axios
          .delete(services.routes.setting, { data: { data: { idconfiguracion: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Valor eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    deleteGlobal(){
      let aux = [];
      for (let i = 0; i < this.auxValue.length; i++) {
        if(!this.auxValue[i].delete){
          aux.push(this.auxValue[i])
        }
      }
      this.auxValue = aux
    },
    addGlobal(value, key){
      let info = key ? key : ''
      this.auxValue.push({
          delete: false,
          key: info,
          value: info == '' ? info : this.forms[value].valores[info],
        });
    },
    async infoGlobal(value){
       this.forms[value].valores = {}
      for (let data of this.auxValue) {
        this.forms[value].valores[data.key] = isNaN(data.value) ? data.value : parseFloat(data.value)
      }
    },
  },
};
</script>

<style scoped></style>
